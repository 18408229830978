import { gameAPI } from "@/services";
import { DeskBettingsParams, DeskPlayerInfoParams } from "@/utils/wsEvent";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Shoes } from "../types/bet.d";

export enum DeskStatus {
  OPEN = "OPEN",
  COUNT = "COUNT",
  DISABLE = "DISABLE",
}

export enum BetType {
  BANKER = "banker",
  PLAYER = "player",
  TIE = "tie",
  PDOUBLE = "playerDouble",
  BDOUBLE = "bankerDouble",
}

export enum DeskResult {
  BANKER = "BANKER",
  PLAYER = "PLAYER",
  TIE = "TIE",
  NONE = "",
}

interface Result {
  banker: string[];
  player: string[];
  bankerTotal: number;
  playerTotal: number;
  result: "banker" | "player" | "tie";
}
interface State {
  seatNo: string;
  queues: Array<{ current: number; category: string }>;
  current: number;
  /** 是否显示快捷投注 */
  showQuickBet: boolean;
  showResult: DeskResult;
  /** 桌台状态 开放 倒计时 停止下注 */
  deskStatus: DeskStatus;
  askMode: string;
  chipsStack: Record<Shoes, number[]>[];
  /** 是否处于开奖 */
  isNowLottery: number[];
  lotteryResults: Partial<Result>[];
  /** 截止目前所有的开奖 */
  currentResult: string;
  /** 快捷投注 */
  quickBet: {
    deskNo: string;
    game_no: string;
    game_mod: number;
    deskId: number;
    type: string;
    countDown: number;
    id: string;
    chipsStack: Record<Shoes, number[]>;
    desk_limits: any[];
    player_bet_info: any[];
    history_result: string;
  };
  /** 游戏公告 */
  gameAnnounce: [];
  /** 桌台玩家详情 */
  deskPlayers: Omit<DeskPlayerInfoParams, "desk_no" | "desk_id">[];
  /** 玩家投注情况 */
  deskBetInfo: Omit<DeskBettingsParams, "desk_no">[];
}

const name = "luxury";

const initialState: State = {
  seatNo: "1",
  current: 0,
  deskStatus: DeskStatus.OPEN,
  askMode: "",
  queues: [],
  showQuickBet: false,
  chipsStack: [
    {
      playerDouble: [],
      tie: [],
      bankerDouble: [],
      player: [],
      banker: [],
      lucky6Pair: [],
      twoCardsLucky6: [],
      lucky6: [],
      threeCardsLucky6: [],
      Lucky6Tie: [],
    },
    {
      playerDouble: [],
      tie: [],
      bankerDouble: [],
      player: [],
      banker: [],
      lucky6Pair: [],
      twoCardsLucky6: [],
      lucky6: [],
      threeCardsLucky6: [],
      Lucky6Tie: [],
    },
    {
      playerDouble: [],
      tie: [],
      bankerDouble: [],
      player: [],
      banker: [],
      lucky6Pair: [],
      twoCardsLucky6: [],
      lucky6: [],
      threeCardsLucky6: [],
      Lucky6Tie: [],
    },
    {
      playerDouble: [],
      tie: [],
      bankerDouble: [],
      player: [],
      banker: [],
      lucky6Pair: [],
      twoCardsLucky6: [],
      lucky6: [],
      threeCardsLucky6: [],
      Lucky6Tie: [],
    },
    {
      playerDouble: [],
      tie: [],
      bankerDouble: [],
      player: [],
      banker: [],
      lucky6Pair: [],
      twoCardsLucky6: [],
      lucky6: [],
      threeCardsLucky6: [],
      Lucky6Tie: [],
    },
    {
      playerDouble: [],
      tie: [],
      bankerDouble: [],
      player: [],
      banker: [],
      lucky6Pair: [],
      twoCardsLucky6: [],
      lucky6: [],
      threeCardsLucky6: [],
      Lucky6Tie: [],
    },
  ],
  isNowLottery: [0, 0, 0, 0],
  lotteryResults: [{}, {}, {}, {}],
  currentResult: "tpbppbbtttwefijkbpbghijkbbpt",
  showResult: DeskResult.NONE,
  quickBet: {} as any,
  deskPlayers: [],
  deskBetInfo: [],
  gameAnnounce: [],
};

// 获取游戏跑马灯
export const getGameAnnouncement = createAsyncThunk("game/getGameAnnouncement2", async (params: any, thunkAPI) => {
  const response = await gameAPI.getGameAnnouncement(params);
  return response;
});

// 获取桌台投注统计
export const getDeskBetInfo = createAsyncThunk("game/getDeskBetInfo", async (params: any, thunkAPI) => {
  console.log(params, "paramsparamsparams");
  const response = await gameAPI.getGameBetInfo(params);
  return response;
});

const betSlice = createSlice({
  name,
  initialState,
  reducers: {
    // showQuickBet
    setshowQuickBet(state, action: PayloadAction<boolean>) {
      state.showQuickBet = action.payload;
    },
    setShowResult(state, action: PayloadAction<DeskResult>) {
      state.showResult = action.payload;
    },
    setDeskStatus(state, action: PayloadAction<DeskStatus>) {
      state.deskStatus = action.payload;
    },
    setQuickBet(state, action: PayloadAction<any>) {
      state.quickBet = action.payload;
    },
    setDeskPlayers(state, action: PayloadAction<Omit<DeskPlayerInfoParams, "desk_no" | "desk_id">[]>) {
      console.log("更新玩家列表=====", action.payload);
      state.deskPlayers = action.payload;
    },
    setDeskBetInfo(state, action: PayloadAction<Omit<DeskBettingsParams, "desk_no">[]>) {
      state.deskBetInfo = action.payload;
      // 每次桌台投注更新新，同步玩家的余额
    },
    addNewDesk(state) {
      state.chipsStack = [
        ...state.chipsStack,
        {
          playerDouble: [],
          tie: [],
          bankerDouble: [],
          player: [],
          banker: [],
          lucky6Pair: [],
          twoCardsLucky6: [],
          lucky6: [],
          threeCardsLucky6: [],
          Lucky6Tie: [],
        },
      ];
    },
    setChipsStack(state, action: PayloadAction<any>) {
      console.log("PayloadActionPayloadAction", action.payload);
      const { desk, chipStack } = action.payload;
      state.chipsStack[desk] = chipStack;
    },
    resetChipsStack(state, action: PayloadAction<number>) {
      state.chipsStack[action.payload] = {
        playerDouble: [],
        tie: [],
        bankerDouble: [],
        player: [],
        banker: [],
        lucky6Pair: [],
        twoCardsLucky6: [],
        lucky6: [],
        threeCardsLucky6: [],
        Lucky6Tie: [],
      };
    },
    resetAllChipsStack(state, action: PayloadAction<void>) {
      state.chipsStack = [
        {
          playerDouble: [],
          tie: [],
          bankerDouble: [],
          player: [],
          banker: [],
          lucky6Pair: [],
          twoCardsLucky6: [],
          lucky6: [],
          threeCardsLucky6: [],
          Lucky6Tie: [],
        },
        {
          playerDouble: [],
          tie: [],
          bankerDouble: [],
          player: [],
          banker: [],
          lucky6Pair: [],
          twoCardsLucky6: [],
          lucky6: [],
          threeCardsLucky6: [],
          Lucky6Tie: [],
        },
        {
          playerDouble: [],
          tie: [],
          bankerDouble: [],
          player: [],
          banker: [],
          lucky6Pair: [],
          twoCardsLucky6: [],
          lucky6: [],
          threeCardsLucky6: [],
          Lucky6Tie: [],
        },
        {
          playerDouble: [],
          tie: [],
          bankerDouble: [],
          player: [],
          banker: [],
          lucky6Pair: [],
          twoCardsLucky6: [],
          lucky6: [],
          threeCardsLucky6: [],
          Lucky6Tie: [],
        },
        {
          playerDouble: [],
          tie: [],
          bankerDouble: [],
          player: [],
          banker: [],
          lucky6Pair: [],
          twoCardsLucky6: [],
          lucky6: [],
          threeCardsLucky6: [],
          Lucky6Tie: [],
        },
        {
          playerDouble: [],
          tie: [],
          bankerDouble: [],
          player: [],
          banker: [],
          lucky6Pair: [],
          twoCardsLucky6: [],
          lucky6: [],
          threeCardsLucky6: [],
          Lucky6Tie: [],
        },
      ];
    },
    setCurrent(state, action: PayloadAction<number>) {
      console.log(action, "actionactionaction");
      state.current = action.payload;
    },
    setIsLottery(state, action: PayloadAction<{ deskNo: number; isLottery: number }>) {
      const { deskNo, isLottery } = action.payload;
      state.isNowLottery[deskNo] = isLottery;
    },
    setLotteryResults(state, action: PayloadAction<{ deskNo: number; result: Result }>) {
      const { deskNo, result } = action.payload;
      state.lotteryResults[deskNo] = result;
    },
    setCurrentResult(state, action: PayloadAction<string>) {
      state.currentResult = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getGameAnnouncement.fulfilled, (state, action: PayloadAction<any>) => {
      state.gameAnnounce = action.payload.data?.list || [];
    });
    builder.addCase(getDeskBetInfo.fulfilled, (state, action: PayloadAction<any>) => {
      state.deskBetInfo = action.payload.data || [];
    });
  },
});

export const selectLotteryResults = (state: { [x: string]: any }) => state.multi.lotteryResults;

export const {
  setChipsStack,
  resetChipsStack,
  resetAllChipsStack,
  setIsLottery,
  setshowQuickBet,
  setQuickBet,
  setDeskBetInfo,
  setDeskPlayers,
} = betSlice.actions;

export default betSlice;
